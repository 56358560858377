<template>
  <mobile-screen
    :header="true"
    screen-class="time-zone-screen gray-bg icon-app1"
  >
    <template v-slot:header>
      <top-header-menu-wrapper
        menu-class="icon-hea1"
        :helpOnline="
          Boolean(
            helpOnline[helpSlug] &&
              helpOnline[helpSlug].name &&
              helpOnline[helpSlug].content
          )
        "
        :helpOnlineRoute="{
          name: helpOnlineLinkName
        }"
      >
        <template v-slot:left>
          <router-link :to="{ name: backLinkName, params: $route.params }">
            <icon icon="#cx-hea1-arrow-left" />
          </router-link>
        </template>
        <div class="component-title">
          {{ displayLabelName("global.timezone.timezone") }}
        </div>
      </top-header-menu-wrapper>
      <section class="search-wrapper time-zone-search">
        <div class="search-field">
          <input
            type="text"
            @keyup="searchQuery = $event.target.value"
            :placeholder="displayLabelName('global.timezone.search')"
          />
          <div class="icon search">
            <icon icon="#cx-app1-search-16x16" width="16" height="16" />
          </div>
        </div>
      </section>
    </template>
    <ul class="clebex-item-section" v-if="!selecting">
      <li class="clebex-item-section-item full-right-underline">
        <div class="clebex-item-content-wrapper">
          <dl class="clebex-item-dl justify-start">
            <dt class="clebex-item-dt">
              <div class="checkbox slide">
                <input id="timeZoneRadio" type="checkbox" v-model="automatic" />
                <label for="timeZoneRadio"></label>
              </div>
            </dt>
            <dd class="clebex-item-dd justify-start">
              {{ displayLabelName("global.timezone.set-automatically") }}
            </dd>
          </dl>
        </div>
      </li>
    </ul>
    <ul
      class="clebex-item-section"
      v-if="listOfTimezones && listOfTimezones.length"
    >
      <li
        v-for="zone in listOfTimezones"
        :key="zone.id"
        class="clebex-item-section-item"
      >
        <div class="clebex-item-content-wrapper">
          <button class="label small-text" @click="selectTimezone(zone)">
            {{ zone.name.replace(/ \*/g, ", ").replace("UTC ", "UTC+") }}
          </button>
          <span class="follow-up-icons">
            <span class="follow-up-icon-item">
              <icon
                :icon="[
                  selectedTimezone && zone.id == selectedTimezone.id
                    ? '#cx-app1-checkmark'
                    : '#cx-app1-arrow-right-12x12'
                ]"
                :width="[
                  selectedTimezone && zone.id == selectedTimezone.id
                    ? '20'
                    : '12'
                ]"
                :height="[
                  selectedTimezone && zone.id == selectedTimezone.id
                    ? '20'
                    : '12'
                ]"
              ></icon>
            </span>
          </span>
        </div>
      </li>
    </ul>
  </mobile-screen>
  <router-view />
</template>

<script>
import MobileScreen from "@/layouts/MobileScreen";
import { mapActions, mapState, mapGetters } from "vuex";
import TopHeaderMenuWrapper from "@/components/global/TopHeaderMenuWrapper";
import helpOnlineMixin from "@/services/mixins/help_online/help-online-mixin";

export default {
  name: "TimeZoneSelection",
  mixins: [helpOnlineMixin],
  data() {
    return {
      selecting: false,
      alphabet: [...Array(26)]
        .map((_, y) => String.fromCharCode(y + 65))
        .join(""),
      searchQuery: "",
      helpSlug: "levels-time-zone"
    };
  },
  mounted() {
    this.$nextTick(() => {
      if (this.automaticTimezone && this.automatic) {
        this.setSelectedTimezone(this.automaticTimezone);
      }
    });
  },
  watch: {
    selectedTimezone() {
      this.setSelecting(false);
      if (this.selectedTimezone) {
        this.$router.push({
          name: this.backLinkName,
          params: this.$route.params
        });
      }
    },
    automatic() {
      if (this.automatic) {
        this.setSelectedTimezone(this.automaticTimezone);
      }
    }
  },
  computed: {
    ...mapState("timezone", ["timezones", "selectedTimezone", "unsetTimezone"]),
    ...mapGetters("timezone", ["automaticTimezone"]),
    automatic: {
      get() {
        return this.$store.state.timezone.automatic;
      },
      set(value) {
        this.$store.commit("timezone/setAutomaticTimezoneSelection", value, {
          root: true
        });
      }
    },
    listOfTimezones() {
      const query = this.searchQuery;
      let timezones = [];
      if (this.timezones && this.timezones.data && this.timezones.data.length) {
        if (query && query.length > 1) {
          timezones = this.timezones.data.filter(item =>
            item.name.toLowerCase().includes(query.toLowerCase())
          );
        } else {
          timezones = this.timezones.data;
        }

        return timezones.sort((a, b) => {
          if (this.selectedTimezone && b.id == this.selectedTimezone.id) {
            return 1;
          }
          if (this.selectedTimezone && a.id == this.selectedTimezone.id) {
            return -1;
          }

          if (a.id < b.id) {
            return -1;
          } else if (a.id > b.id) {
            return 1;
          }

          return 0;
        });
      }
      return null;
    }
  },
  methods: {
    ...mapActions("timezone", [
      "setSelectedTimezone",
      "setAutomaticTimezoneSelection",
      "setUnsetTimezoneStatus"
    ]),
    setSelecting(payload) {
      this.selecting = payload;
    },
    selectTimezone(zone) {
      this.$store.commit("timezone/setAutomaticTimezoneSelection", null, {
        root: true
      });
      this.setSelectedTimezone(zone);
      this.$router.push({
        name: this.backLinkName,
        params: this.$route.params
      });
    }
  },
  components: {
    MobileScreen,
    TopHeaderMenuWrapper
  },
  props: {
    backLinkName: {
      type: String,
      required: true
    },
    helpOnlineLinkName: {
      type: String
    }
  }
};
</script>
